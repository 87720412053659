import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { WindowRefService } from './window-ref.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  
  private innerWidth: number;

  get onResize$(): Observable<Window> {
    return this.resizeBehaviorSubject.asObservable();
  }
  
  getWindowSize(): Window {
    return this.resizeBehaviorSubject.value;
  }
 
  private resizeBehaviorSubject: BehaviorSubject<Window>;

  constructor(
    private eventManager: EventManager,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService
    ) {
      if (isPlatformBrowser(this.platformId)) {
        this.resizeBehaviorSubject = new BehaviorSubject(windowRefService.nativeWindow);
        window.addEventListener('resize', this.onResize.bind(this));
        this.innerWidth = windowRefService.nativeWindow.innerWidth;
      }
  }

  private onResize(event: UIEvent) {
    let window = event.target as Window;
    if(window.innerWidth !== this.innerWidth) {
      // console.log('Resize event emitted');
      this.resizeBehaviorSubject.next(window);
    }
  }

}
